/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-email-marketing:before { content: "\f100"; }
.flaticon-next-button:before { content: "\f101"; }
.flaticon-back-arrow:before { content: "\f102"; }
.flaticon-plus:before { content: "\f103"; }
.flaticon-minus:before { content: "\f104"; }
.flaticon-chevron:before { content: "\f105"; }
.flaticon-back:before { content: "\f106"; }
.flaticon-menu:before { content: "\f107"; }
.flaticon-search:before { content: "\f108"; }
.flaticon-shopping-cart:before { content: "\f109"; }
.flaticon-down-arrow:before { content: "\f10a"; }
.flaticon-play-button:before { content: "\f10b"; }
.flaticon-research:before { content: "\f10c"; }
.flaticon-speed:before { content: "\f10d"; }
.flaticon-menu-1:before { content: "\f10e"; }
.flaticon-web-settings:before { content: "\f10f"; }
.flaticon-conversation:before { content: "\f110"; }
.flaticon-megaphone:before { content: "\f111"; }
.flaticon-keywords:before { content: "\f112"; }
.flaticon-content-management:before { content: "\f113"; }
.flaticon-ppc:before { content: "\f114"; }
.flaticon-competitor:before { content: "\f115"; }
.flaticon-saxophone:before { content: "\f116"; }
.flaticon-startup:before { content: "\f117"; }
.flaticon-quote:before { content: "\f118"; }
.flaticon-quote-1:before { content: "\f119"; }
.flaticon-jigsaw:before { content: "\f11a"; }
.flaticon-paper-plane:before { content: "\f11b"; }
.flaticon-diamond:before { content: "\f11c"; }
.flaticon-share-icon:before { content: "\f11d"; }
.flaticon-content:before { content: "\f11e"; }
.flaticon-darts:before { content: "\f11f"; }
.flaticon-bar-chart:before { content: "\f120"; }
.flaticon-link:before { content: "\f121"; }
.flaticon-reputation:before { content: "\f122"; }